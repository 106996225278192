<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.nickname" placeholder="请输入用户昵称" clearable />
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.cellphone" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.authIdCardStatus" placeholder="请选择实名状态" clearable>
            <el-option v-for="(value, key) in authIdCardStatus" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="change"
            />
          </el-form-item>
          <el-select v-model="query.rechargeStatus" placeholder="请选择充值状态" clearable>
            <el-option v-for="(value, key) in rechargeStatus" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="nickname"
        label="用户昵称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号码"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="posterType"
        label="实名认证"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ authIdCardStatus[row.authIdCardStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="rechargeAmount"
        label="充值金额"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="point"
        label="对应金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createTime"
        label="充值时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="rechargeStatus"
        label="充值状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ rechargeStatus[row.rechargeStatus] }}
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/recharge'
export default {
  mixins: [list],
  data() {
    return {
      api,
      authIdCardStatus: {
        0: '未认证',
        1: '认证申请中',
        2: '认证通过',
        3: '认证失败'
      },
      rechargeStatus: {
        0: '未支付',
        1: '支付中',
        2: '支付成功',
        3: '支付失败'
      },
      createTime: []
    }
  },
  methods: {
    change(val) {
      this.query.beginTime = val[0]
      this.query.endTime = val[1]
    }
  }
}
</script>
