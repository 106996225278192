import request from '@/utils/axios'

/** 获取充值列表 */
export function getList(params) {
  return request({
    url: '/recharge',
    method: 'get',
    params
  })
}
